import React from "react";
import Helmet from "react-helmet";

import Product from "../components/Products.js"



export default () => ( 
    <div>
        <p>Work in progress</p>
    </div>
)
